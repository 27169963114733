import React, { useEffect, useState } from 'react';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import styled, { createGlobalStyle } from 'styled-components';
import DbLogo from './assets/img/db-logo.png';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    font: inherit;
    color: inherit;
    text-decoration: inherit;
    line-height: inherit;
    border: none;
    background: none;
    outline: none;
  }
  input:focus {
    box-shadow: 0 0 5px rgba(0,0,0,.15)
  }
  body {
    font-family: sans-serif;
    font-size: 16px;
    color: #333;
  }
  .login-form {
    width: 250px;
    min-height: 330px;
    overflow: hidden;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: 60px auto;
    background: #f3f3f3;
    position: relative;
    border: 1px solid #eaeaea;
    padding: 15px;
    border-radius: 4px;
    h1 {
      flex-shrink: 0;
      width: 100%;
      height: 50px;
      color: rgba(0,0,0,0);
      overflow: hidden;
      background: url(${DbLogo}) no-repeat center top;
      background-size: contain;
      & + p {
        text-align: center;
        font-size: 14px;
        margin: 10px 0 20px;
      }
    }
    input {
      background: #fff;
      padding: 10px;
      display: block;
      width: 100%;
      box-sizing: border-box;
      margin: 5px 0 10px;
      border: 1px solid #eaeaea;
      border-radius: 4px;
    }
    [type=button] {
      text-align: right;
      font-size: 0.8em;
      text-decoration: underline;
      color: #333;
      margin: 0 0 20px;
      cursor: pointer;
    }
    [type=submit] {
      background: #069;
      color: #fff;
      font-weight: bold;
      padding: 15px 20px;
      cursor: pointer;
      border-radius: 4px;
    }
  }
  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    img {
      height: 50px;
      max-width: 38vw;
      object-fit: contain;
    }
  }
  .app-frame {
    background: #eee;
    flex: 1;
  }
  .my-products {
    width: 600px;
    max-width: calc(100vw - 25px);
    margin: 30px auto;
    box-sizing: border-box;
    padding: 20px;
    background: #fff;
    border: 1px solid #eaeaea;
    h1 {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    ul {
      list-style-position: inside;
      margin-top: 20px;
      a {
        color: #069;
        text-decoration: underline;
      }
    }
  }
`;

const LoaderIcon = styled.svg`
  @keyframes spin {
    0% { transform: rotate(0) }
    100% { transform: rotate(359deg) }
  }
  animation: spin .6s linear 0s infinite;
`;

const Loader = () => (
  <LoaderIcon width="64" height="64" viewBox="0 0 410 410">
    <path fill="#777" d="M205 410C91.7816 410 0 318.218 0 205C0 91.7816 91.7816 0 205 0C318.218 0 410 91.7816 410 205H379C379 108.902 301.098 31 205 31C108.902 31 31 108.902 31 205C31 301.098 108.902 379 205 379V410Z" />
  </LoaderIcon>
);

firebase.initializeApp({
  apiKey: "AIzaSyDL0vQgx-pafb4-u6mNnt3DfDj4t4sKlUQ",
  authDomain: "digital-bloom.firebaseapp.com",
  databaseURL: "https://digital-bloom.firebaseio.com",
  projectId: "digital-bloom",
  storageBucket: "digital-bloom.appspot.com",
  messagingSenderId: "922689200083",
  appId: "1:922689200083:web:d8872367fc6cd403269926"
});

type MemberArea = {
  title: string,
  id: string,
  html?: string,
  file?: string
};

function App() {
  const [loggedUser, setLoggedUser] = useState(firebase.auth().currentUser);
  const [loggingIn, setLoggingIn] = useState(false);
  const [userAreas, setUserAreas] = useState<MemberArea[]>([]);
  const [showingProduct, setShowingProduct] = useState<string | null>(null);
  
  useEffect(() => firebase
    .auth()
    .onAuthStateChanged(setLoggedUser),
    []
  );

  useEffect(() => {
    loggedUser && firebase
      .firestore()
      .doc(`users/${loggedUser.uid}`)
      .get()
      .then(async user => {
        const areas: string[] | undefined = user.data()?.memberAreas;
        areas && setUserAreas(
          await Promise.all(areas.map(
            async areaId => ({
              ...(
                await firebase
                  .firestore()
                  .doc(`member-areas/${areaId}`)
                  .get()
              ).data(),
              id: areaId
            }) as MemberArea
          ))
        );
      })
      .catch(console.error);
  }, [loggedUser]);

  return (
    <>
      <GlobalStyle />
      {!loggedUser && (
        <form
          className="login-form"
          onSubmit={async ev => {
            ev.preventDefault();
            setLoggingIn(true);
            const { email, password } = ev.currentTarget;
            try {
              await firebase.auth().signInWithEmailAndPassword(email.value, password.value);
            } catch (e) {
              alert('Usuário ou senha inválidos! Por favor, verifique os dados e tente novamente.');
            }
            setLoggingIn(false);
          }}>
          <h1>Área de Membros da Digital Bloom</h1>
          <p>Entre para acessar seus produtos</p>
          <label>
            E-mail
            <input required name="email" defaultValue="" type="email" />
          </label>
          <label>
            Senha
            <input required name="password" defaultValue=""  type="password" />
          </label>
          <button
            type="button"
            onClick={async ev => {
              const form = ev.currentTarget.parentElement as HTMLFormElement;
              if (!form.email.value) {
                form.email.reportValidity();
                return;
              }
              setLoggingIn(true);
              try {
                await firebase.auth().sendPasswordResetEmail(form.email.value, {
                  url: 'https://membros.digitalbloom.com.br'
                });
                alert('Um link de redefinição de senha foi enviado para o seu e-mail.');
              } catch {
                alert('E-mail inválido!');
              }
              setLoggingIn(false);
            }}>
            Esqueci minha senha
          </button>
          <button type="submit">Entrar</button>
          {loggingIn && (
            <div
              style={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                left: '0',
                top: '0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: 'inherit'
              }}>
              <Loader />
            </div>
          )}
        </form>
      )}
      {loggedUser && (
        <div style={{ width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column' }}>
          <header className="top-bar">
            <img src={DbLogo} alt="Logotipo da Digital Bloom" />
            <div>
              <a
                href="/meus-produtos"
                onClick={ev => {
                  ev.preventDefault();
                  setShowingProduct(null);
                }}>
                Meus produtos
              </a>
              {' | '}
              <a
                href="/sair"
                onClick={ev => {
                  ev.preventDefault();
                  firebase.auth().signOut();
                }}>
                Sair
              </a>
            </div>
          </header>
          {!showingProduct && (
            <div className="app-frame">
              <div className="my-products">
                <h1>Meus produtos</h1>
                <p>Clique no produto desejado para acessá-lo.</p>
                <ul>
                  {userAreas.map(area => (
                    <li key={area.id}>
                      <a
                        href={`/areas/${area.id}`}
                        onClick={ev => {
                          ev.preventDefault();
                          area.html && setShowingProduct(
                            URL.createObjectURL(new Blob(
                              [area
                                .html
                                .replace(/(href="\/\/)/gi, 'href="https://')
                                .replace(/(src="\/\/)/gi, 'src="https://')
                                .replace(/(url\("\/\/)/gi, 'url("https://')
                              ],
                              { type: 'text/html' }
                            ))
                          );
                        }}>
                        {area.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          {showingProduct && (
            <iframe className="app-frame" title="Área de Membros" src={showingProduct}></iframe>
          )}
        </div>
      )}
    </>
  );
}

export default App;
